'use strict';

(function($){
  var app = {

    init: function() {
      this.nav();
      this.cookiesAccept();
      this.cookiesConfig();
      this.lazyframe();
      this.likeContent();
      return false;
    },
    nav: function() {

      var body = document.getElementsByTagName('body')[0],
      btnNav = document.getElementsByClassName('btn-nav')[0],
      btnClose = document.getElementsByClassName('btn-close')[0],
      backdrop = document.getElementsByClassName('backdrop')[0]

      if(typeof(btnNav) != 'undefined' && btnNav != null){
        btnNav.addEventListener('click', function(){
          body.classList.add('nav-opened')
        })
      }

      if(typeof(btnClose) != 'undefined' && btnNav != null){
        btnClose.addEventListener('click', function(){
          body.classList.remove('nav-opened')
        })
      }

      if(typeof(backdrop) != 'undefined' && btnNav != null){
        backdrop.addEventListener('click', function(){
          body.classList.remove('nav-opened')
        })
      }

      navbar_height = document.querySelector('header').offsetHeight;  
      window.addEventListener('scroll', function() {
        if (window.scrollY > 350) {
          document.getElementById('header').classList.add('header-fixed');
        } else {
          document.getElementById('header').classList.remove('header-fixed');
        } 
      })

    },
    cookiesAccept: function () {
      var msgCookies = $('#cookies-msg');
      var btnAccept = $('.btn-cookies-accept');
      var btnReject = $('.btn-cookies-reject');
      var btnConfig = $('.btn-cookies-configure');
      var popupConfig = $('#popup-cookies-config');
      btnAccept.click(function (e){
        e.preventDefault();
        $.cookie(keyAnalyticCookies, '1', { expires: 7, path: '/' });
        $.cookie(keyMarketingCookies, '1', { expires: 7, path: '/' });
        $.cookie(keyRrssCookies, '1', { expires: 7, path: '/' });
        msgCookies.slideUp();
        msgCookies.parent().hide();
      })
      btnReject.click(function (e){
        e.preventDefault();
        $.cookie(keyAnalyticCookies, '0', { expires: 7, path: '/' });
        $.cookie(keyMarketingCookies, '0', { expires: 7, path: '/' });
        $.cookie(keyRrssCookies, '0', { expires: 7, path: '/' });
        msgCookies.slideUp();
        msgCookies.parent().hide();
      })
      btnConfig.click(function (e)
      {
        e.preventDefault();
        popupConfig.slideDown();
      })
    },
    cookiesConfig: function (){
      var configContainer = $('.cookies-configuration-block');
      var msgCookies = $('#over-cookies-msg');6
      var popupConfig = $('#popup-cookies-config');

      if(typeof configContainer.html() !== 'undefined')
      {
        var checkAll = configContainer.find('input#cookies-todas');
        var checkAnalytics = configContainer.find('input#cookies-analiticas');
        var checkMarketing = configContainer.find('input#cookies-marketing');
        var checkRrss = configContainer.find('input#cookies-rrss');
        var buttonSave = configContainer.find('.btn-save-config');
        var msgSuccess = configContainer.find('#text-success');
        var msgError = configContainer.find('#text-error');

        checkAll.click(function ()
        {
          if($(this).prop('checked'))
          {
            checkAnalytics.prop('checked',true);
            checkMarketing.prop('checked',true);
            checkRrss.prop('checked',true);
          }
          else
          {
            checkAnalytics.prop('checked',false);
            checkMarketing.prop('checked',false);
            checkRrss.prop('checked',false);
          }
        })
        buttonSave.click(function ()
        {
          msgSuccess.addClass('d-none');
          msgError.addClass('d-none');
          $.cookie(keyAnalyticCookies, ((checkAnalytics.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          $.cookie(keyMarketingCookies, ((checkMarketing.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          $.cookie(keyRrssCookies, ((checkRrss.prop('checked')) ? '1' : '0'), { expires: 7, path: '/' });
          if(typeof msgCookies.html() !== 'undefined') msgCookies.hide();
          msgSuccess.removeClass('d-none');
          if(typeof popupConfig.html() !== 'undefined') setTimeout(function ()
          {
            popupConfig.hide()
          },500);
        })
      }
    },
    lazyframe: function () {
      lazyframe('.lazyframe', {
        apikey: 'AIzaSyBG22hzTpV5lxNFeYgNDuv1SSD_NBEAu9k', // cambiar esta API por la del proyecto https://github.com/vb/lazyframe#apikey
        debounce: 250,

        onAppend: (function (lazyframe) {
          return console.log(lazyframe.parentNode.classList.add("loaded"))
        })
      });
    },
    likeContent : function (){
      $('.btn-like:not(.active)').click(function ()
      {
        var obj = $(this);
        var table = obj.data('label');
        var item = obj.data('item');
        var number = obj.find('span.like');

        $.ajax(
            {
              url : site_url + '/add_like',
              method : 'POST',
              dataType : 'json',
              data : {table : table, item : item},
              success : function (data)
              {
                if(!data.error)
                {
                  number.html(data.total);
                  obj.addClass('active');
                  obj.off('click');
                }
              }
            }
        )

      })
    }
  };
  $(function(){
    app.init()
  });
})(jQuery);